import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
// Components & UI
import ProfileLayout from 'components/layouts/profile/profile.layout';
import { MessageForm } from 'components/message-form/message-form.component';

import { accountFetchMessagesRoutine } from 'state/account/account.routines';
// States
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { getPhoneNumber } from 'util/globalVariables';

// Styles
import './compose.style.scss';

const FamilyAccount: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onMessagesFormCancel = () => {
        dispatch(closeModal({}));
        navigate('/secure/profile/messages');
    };

    const submissionCallbacks = {
        onSuccess: () => {
            dispatch(
                openModal({
                    showClose: true,
                    bodyContent: (
                        <BirdiModalContent
                            icon={'success'}
                            title={t(`pages.profile.messages.callbacks.success`)}
                            body={t(`pages.profile.messages.callbacks.successMessage`)}
                        />
                    ),
                    ctas: [
                        {
                            label: t(`pages.profile.messages.labels.gotIt`),
                            variant: 'primary',
                            onClick: onMessagesFormCancel,
                            dataGALocation: 'ProfileMessagesSuccess'
                        }
                    ],
                    onClose: onMessagesFormCancel
                })
            );
            dispatch(accountFetchMessagesRoutine.trigger());
        },
        onFailure: () => {
            dispatch(
                openModal({
                    showClose: true,
                    type: 'danger',
                    size: 'lg',
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t(`pages.profile.messages.callbacks.error`)} icon="alert" />
                    ),
                    bodyContent: <BirdiModalContentAlt subTitle={t(`pages.profile.messages.callbacks.errorMessage`)} />,
                    ctas: [
                        {
                            label: t(`pages.profile.messages.labels.gotIt`),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'ProfileMessagesError'
                        }
                    ]
                })
            );
            dispatch(accountFetchMessagesRoutine.trigger());
        }
    };

    return (
        <div className="compose-message-page">
            <ProfileLayout
                eyebrowText={t(`pages.profile.eyebrowText`)}
                title={t(`pages.profile.messages.title`)}
                heading={t(`pages.profile.messages.composeMessage`)}
            >
                <Container fluid>
                    <Row>
                        <Col className="d-flex flex-column">
                            <Row>
                                <Col xs={12} md={12} className="pb-4">
                                    <p
                                        className="text-center"
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(
                                                t('forms.messages.formInstructions', {
                                                    phoneNumber: getPhoneNumber({ isEnd: true })
                                                })
                                            )
                                        }}
                                    />
                                </Col>
                            </Row>

                            <MessageForm
                                callbacks={submissionCallbacks}
                                onMessagesFormCancel={onMessagesFormCancel}
                                centeredButtons={false}
                                type="messagesPage"
                            />
                        </Col>
                    </Row>
                </Container>
            </ProfileLayout>
        </div>
    );
};

export default FamilyAccount;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
